import { ImageProps } from "next/legacy/image";
import type { SanityImage } from "../../../lib/sanity/sanity.types";
import { ImageWithBlurHash } from "./ImageWithBlurHash";
import useTailwindBreakpoint from "../../../lib/useTailwindBreakpoint";
import { imageUrlFor } from "../../../lib/sanity/sanity.image";
export type ResponsiveImageType = {
  _type: "responsive_image";
  desktopImage: SanityImage;
  tabletImage?: SanityImage;
  mobileImage?: SanityImage;
  alt?: string;
  quality?: number;
  priority?: boolean;
  fit?: ImageProps["objectFit"];
  position?: ImageProps["objectPosition"];
  layout?: ImageProps["layout"];
};
type ResponsiveImageProp = {
  width?: number;
  height?: number;
  className?: string;
} & ResponsiveImageType;
export const ResponsiveImage = ({
  width,
  height,
  className,
  layout,
  ...props
}: ResponsiveImageProp) => {
  const breakpoint = useTailwindBreakpoint();
  const image = chooseImage(breakpoint, props);
  return <ImageWithBlurHash objectFit={props.fit} objectPosition={props.position} src={imageUrlFor(image)} alt={props.alt || ""} width={width} height={height} quality={props.quality} layout={layout} priority={props.priority} className={className} blurHash={image?.asset?.metadata?.blurHash} data-sentry-element="ImageWithBlurHash" data-sentry-component="ResponsiveImage" data-sentry-source-file="ResponsiveImage.tsx" />;
};
export const chooseImage = (breakpoint: string, props: ResponsiveImageType) => {
  if (["xs", "sm"].includes(breakpoint) && props.mobileImage) {
    return props.mobileImage;
  }
  if (["xs", "sm", "md"].includes(breakpoint) && props.tabletImage) {
    return props.tabletImage;
  }
  return props.desktopImage;
};