/* eslint-disable @typescript-eslint/no-var-requires */
const defaultTheme = require("tailwindcss/defaultTheme");
const plugin = require("tailwindcss/plugin");

const colors = {
  transparent: "transparent",
  current: "currentColor",
  white: "#FFFFFF",
  whiteTransparent: "#FFFFFF7F",
  whiteMixed: "#fbfafb",
  "error-red": "#B00020",

  forrest: "#193C30", // Backwards compatibility
  forest: "#193C30",
  apricot: "#FF9E7D",
  aubergine: "#592141",
  corn: "#FFD394",
  earth: "#7E4239",
  frost: "#B2D3EA",
  grass: "#0E8D64",
  lavender: "#BBB7E2",
  mint: "#A4D6AF",
  mist: "#A0B4BB",
  ocean: "#00396F",
  orange: "#E7634C",
  raspberry: "#DA5E74",
  rose: "#FFB3AC",
  sand: "#DFB995",
  sky: "#3369B7",
  highlight: "#FFF5DA",

  plum: {
    DEFAULT: "#1E1928",
    80: "rgba(30, 25, 40, 0.8)",
    ["80-opaque"]: "#4B4753",
    64: "rgba(30, 25, 40, 0.64)",
    ["64-opaque"]: "#6F6C75",
    40: "rgba(30, 25, 40, 0.4)",
    ["40-opaque"]: "#A5A3A9",
    16: "rgba(30, 25, 40, 0.16)",
    ["16-opaque"]: "#DBDADD",
    8: "rgba(30, 25, 40, 0.08)",
    ["8-opaque"]: "#EDEDEE",
    4: "rgba(30, 25, 40, 0.04)",
    ["4-opaque"]: "#F6F5F7",
    2: "rgba(30, 25, 40, 0.02)",
    ["2-opaque"]: "#FBFAFB",
  },

  beige: {
    DEFAULT: "#EDE6DD",
    64: "#F4EFE9",
    dark: "#dcd6ce",
  },
};

module.exports = {
  content: [
    "./components/**/*.{js,ts,jsx,tsx,json}",
    "./pages/**/*.{js,ts,jsx,tsx}",
    "./data/**/*.{js,ts,jsx,tsx,json}",
    "./stories/**/*.{js,ts,jsx,tsx}",
  ],
  theme: {
    colors: colors,
    screens: {
      xs: "475px",
      ...defaultTheme.screens,
    },
    extend: {
      gridTemplateRows: {
        "9-auto": "repeat(9, auto)",
        "18-auto": "repeat(18, auto)",
      },
      backgroundImage: {
        "ligature-st": 'url("/images/st.svg")',
        "ligature-st-white-16": 'url("/images/st-white-16.svg")',
        "ligature-st-beige": 'url("/images/st-beige.svg")',
        "ligature-wy": 'url("/images/wy.svg")',
        "ligature-demo": 'url("/images/demo.svg")',
        "media-inquiries": 'url("/images/media_inquiries.svg")',
        "media-inquiries-mobile": 'url("/images/media_inquiries_mobile.svg")',
        dropdown: 'url("/images/dropdown_arrow.svg")',
        "dropdown-disabled": 'url("/images/dropdown_arrow.svg")',
        "dropdown-white": 'url("/images/dropdown_arrow_white.svg")',
        leaf: 'url("/images/leaf-background.svg")',
        "leaf-edge": 'url("/images/leaf-edge-background.svg")',
      },
      backgroundSize: {
        50: "50%",
        33: "33%",
      },
      backgroundPosition: {
        "right-4": "center right 1rem",
      },
      width: {
        "120%": "120%",
        "full-150": "calc(100% - 150px)",
        47: "11.75rem",
        49: "12.25rem",
        106: "26.5rem",
        120: "30rem",
        128: "32rem",
        144: "36rem",
        150: "37.5rem",
        168: "42rem",
        224: "56rem",
      },
      maxWidth: {
        120: "30rem",
      },
      minWidth: {
        6: "1.5rem",
        49: "12.25rem",
      },
      height: {
        "80vh": "80vh",
        120: "30rem",
        128: "32rem",
        144: "36rem",
      },
      spacing: {
        15: "3.75rem",
        26: "6.5rem",
        30: "7.5rem",
      },
      scale: {
        60: "0.6",
        133: "1.33",
        200: "2",
      },
      boxShadow: {
        plum: `0 0 0 1px ${colors.plum.DEFAULT}`,
      },

      fontFamily: {
        inter: ["Inter", "sans-serif"],
        empathySerif: ["Empathy", "Georgia", "serif"],
      },

      keyframes: {
        shake: {
          "10%, 90%": { transform: "translateX(-1px)" },
          "20%, 80%": { transform: "translateX(2px)" },
          "30%, 50%, 70%": { transform: "translateX(-4px)" },
          "40%, 60%": { transform: "translateX(4px)" },
        },
        buttonIn: {
          "0%": { transform: "translateY(-40px)" },
          "100%": { transform: "translateY(0px)" },
        },
        fadeIn: {
          "0%": { opacity: 0, transform: "translateY(10px)" },
          "100%": { opacity: 1, transform: "translateY(0px)" },
        },
        "bounce-short": {
          "0%, 20%, 40%, 60%": {
            transform: "translateY(0%)",
            opacity: 1,
            "animation-timing-function": "cubic-bezier(0, 0, 0.2, 1)",
          },
          "10%, 30%, 50%, 70%": {
            transform: "translateY(-15%)",
            opacity: 1,
            "animation-timing-function": "cubic-bezier(0.8, 0, 1, 1)",
          },
          "100%": {
            opacity: 0,
            "animation-timing-function": "cubic-bezier(0.8, 0, 1, 1)",
          },
        },
        dot: {
          "0%": {
            opacity: 0,
            transform: "translateX(0px)",
          },
          "28%": {
            opacity: 1,
          },
          "48%": {
            opacity: 1,
          },
          "76%": {
            opacity: 0,
            transform: "translateX(8px)",
          },
          "100%": {
            opacity: 0,
          },
        },
      },
      animation: {
        shake: "shake 1s ease-in-out both",
        headerButtonIn: "buttonIn 0.5s ease",
        fadeIn: "fadeIn 1s ease",
        "bounce-short": "bounce-short 4s ease-in-out 1 forwards",
        dot: "dot 1783ms linear infinite", // Animation - for loading dots
      },
      outline: {
        blue: "2px solid #3369B7",
        plum: ["2px solid #1E1928", "2px"],
      },
      typography: (theme) => ({
        DEFAULT: {
          css: {
            h1: {
              fontSize: 20,
              lineHeight: 1.5,
              letterSpacing: -0.017,
              fontWeight: 600,
              color: theme("colors.plum.DEFAULT"),
            },
            h2: {
              fontSize: 18,
              lineHeight: 1.5,
              letterSpacing: -0.017,
              fontWeight: 600,
              color: theme("colors.plum.DEFAULT"),
            },
            h3: {
              fontSize: 16,
              lineHeight: 1.5,
              letterSpacing: -0.014,
              fontWeight: 600,
              color: theme("colors.plum.DEFAULT"),
            },
            color: theme("colors.plum.80"),
            "ol > li::before": {
              color: theme("colors.plum.80"),
            },
            "ul > li::before": {
              backgroundColor: theme("colors.plum.80"),
            },
            a: {
              color: theme("colors.plum.80"),
              "&:hover": {
                color: theme("colors.plum.DEFAULT"),
                textDecoration: "none !important",
              },
              fontWeight: "normal",
            },
          },
        },
        harmony: {
          css: {
            color: theme("colors.current"),
            fontSize: 18,
            letterSpacing: "-0.014em",
            p: {
              marginBottom: 16,
            },
            h2: {
              fontSize: 18,
              letterSpacing: "-0.014em",
              fontWeight: 600,
              color: theme("colors.current"),
            },
            a: {
              color: theme("colors.current"),
              "&:hover": { color: theme("colors.current") },
              fontWeight: "normal",
            },
            "--tw-prose-bullets": theme("colors.current"),
            h1: {
              color: theme("colors.current"),
            },
            h3: {
              color: theme("colors.current"),
            },
          },
        },
        articleNew: {
          css: {
            fontSize: 18,
            letterSpacing: "-0.014em",
            color: theme("colors.plum.80"),
            h1: {
              fontSize: 20,
              lineHeight: 1.5,
              letterSpacing: -0.017,
              fontWeight: 500,
              color: theme("colors.plum.DEFAULT"),
              marginBottom: 32,
            },
            h2: {
              fontSize: 18,
              lineHeight: 1.5,
              letterSpacing: -0.017,
              fontWeight: 500,
              color: theme("colors.plum.DEFAULT"),
              marginBottom: 32,
            },
            h3: {
              fontSize: 16,
              lineHeight: 1.5,
              letterSpacing: -0.014,
              fontWeight: 500,
              color: theme("colors.plum.DEFAULT"),
              marginBottom: 32,
            },
            hr: {
              marginTop: 32,
              marginBottom: 32,
            },
            "--tw-prose-hr": theme("colors.plum.40"),
            "--tw-prose-bullets": theme("colors.plum.80"),
            "li > p": {
              marginTop: 0,
              marginBottom: 0,
            },
            li: {
              marginTop: 0.25 + "rem",
              marginBottom: 0.25 + "rem",
            },

            a: {
              color: theme("colors.raspberry"),
              textDecoration: "underline",
              "&:hover": { textDecoration: "none !important" },
            },
          },
        },
        lg: {
          css: {
            h1: {
              fontSize: 24,
              lineHeight: "32px",
              letterSpacing: " -0.019em",
              fontWeight: 600,
              color: theme("colors.plum.DEFAULT"),
            },
            h2: {
              fontSize: 24,
              lineHeight: "32px",
              letterSpacing: "-0.019em",
              fontWeight: 600,
              color: theme("colors.plum.DEFAULT"),
              marginTop: "3em",
              marginBottom: "1em",
            },
            h3: {
              fontSize: 18,
              lineHeight: "26px",
              letterSpacing: "-0.014em",
              fontWeight: 600,
              color: theme("colors.plum.DEFAULT"),
              marginTop: "3em",
              marginBottom: "1em",
            },
            color: theme("colors.plum.80"),
            "ol > li::before": {
              color: theme("colors.plum.80"),
            },
            "ul > li::before": {
              backgroundColor: theme("colors.plum.80"),
            },
            a: {
              color: theme("colors.plum.80"),
              "&:hover": { color: theme("colors.plum.DEFAULT") },
              fontWeight: "normal",
            },
          },
        },
      }),
    },
    placeholderColor: {
      plum: {
        64: colors.plum[64],
      },
    },
  },
  plugins: [
    require("@tailwindcss/typography"),
    plugin(function ({ addComponents, addUtilities }) {
      addUtilities({
        ".text-balance": {
          "text-wrap": "balance",
        },
        ".grayscale": {
          filter: "grayscale(100%)",
        },
        ".filter-to-beige": {
          filter:
            "brightness(0) saturate(100%) invert(98%) sepia(18%) saturate(416%) hue-rotate(306deg) brightness(96%) contrast(93%)",
        },
        ".filter-to-plum": {
          filter:
            "brightness(0) saturate(100%) invert(7%) sepia(8%) saturate(3352%) hue-rotate(218deg) brightness(90%) contrast(91%)",
        },
        ".filter-to-white": {
          filter: "brightness(100)",
        },
        ".no-ligatures": {
          "font-variant-ligatures": "none",
        },
        ".no-scrollbar": {
          /* Hide scrollbar for IE, Edge, and Firefox */
          msOverflowStyle: "none" /* IE and Edge */,
          scrollbarWidth: "none" /* Firefox */,
          /* For Chrome, Safari, and Opera */
          "::-webkit-scrollbar": {
            display: "none",
          },
        },
      });
      addComponents({
        ".text-e1": {
          fontFamily: "Empathy",
          fontWeight: 400,
          fontSize: 80,
          lineHeight: "90px",
          letterSpacing: 0,
          "-webkit-font-smoothing": "antialiased",
        },

        ".text-e2": {
          fontFamily: "Empathy",
          fontWeight: 400,
          fontSize: 64,
          lineHeight: "74px",
          letterSpacing: 0,
          "-webkit-font-smoothing": "antialiased",
        },

        ".text-e3": {
          fontFamily: "Empathy",
          fontWeight: 400,
          fontSize: 48,
          lineHeight: "58px",
          letterSpacing: 0,
          "-webkit-font-smoothing": "antialiased",
        },

        ".text-e4": {
          fontFamily: "Empathy",
          fontWeight: 400,
          fontSize: 40,
          lineHeight: "50px",
          letterSpacing: 0,
          "-webkit-font-smoothing": "antialiased",
        },

        ".text-e5": {
          fontFamily: "Empathy",
          fontWeight: 400,
          fontSize: 32,
          lineHeight: "42px",
          letterSpacing: 0,
          "-webkit-font-smoothing": "antialiased",
        },

        ".text-e6": {
          fontFamily: "Empathy",
          fontWeight: 400,
          fontSize: 24,
          lineHeight: "34px",
          letterSpacing: 0,
          "-webkit-font-smoothing": "antialiased",
        },

        ".text-e7": {
          fontFamily: "Empathy",
          fontWeight: 400,
          fontSize: 20,
          lineHeight: "30px",
          letterSpacing: 0,
          "-webkit-font-smoothing": "antialiased",
        },

        ".text-h1": {
          fontFamily: "Inter",
          fontWeight: 600,
          fontSize: 24,
          lineHeight: "32px",
          letterSpacing: "-0.019em",
          "-webkit-font-smoothing": "antialiased",
        },

        ".text-h2": {
          fontFamily: "Inter",
          fontWeight: 600,
          fontSize: 20,
          lineHeight: "28px",
          letterSpacing: "-0.017em",
          "-webkit-font-smoothing": "antialiased",
        },

        ".text-h3": {
          fontFamily: "Inter",
          fontWeight: 600,
          fontSize: 18,
          lineHeight: "26px",
          letterSpacing: "-0.014em",
          "-webkit-font-smoothing": "antialiased",
        },

        ".text-h4": {
          fontFamily: "Inter",
          fontWeight: 600,
          fontSize: 16,
          lineHeight: "24px",
          letterSpacing: "-0.014em",
          "-webkit-font-smoothing": "antialiased",
        },

        ".text-body-large": {
          fontFamily: "Inter",
          fontSize: 18,
          lineHeight: "30px",
          letterSpacing: "-0.014em",
          "-webkit-font-smoothing": "antialiased",
        },

        ".text-body-standard": {
          fontFamily: "Inter",
          fontSize: 16,
          lineHeight: "24px",
          letterSpacing: "-0.011em",
          "-webkit-font-smoothing": "antialiased",
        },

        ".text-body-small": {
          fontFamily: "Inter",
          fontSize: 14,
          lineHeight: "22px",
          letterSpacing: "-0.006em",
          "-webkit-font-smoothing": "antialiased",
        },

        ".text-body-tiny": {
          fontFamily: "Inter",
          fontSize: 12,
          lineHeight: "20px",
          letterSpacing: 0,
          "-webkit-font-smoothing": "antialiased",
        },

        ".text-body-label": {
          fontFamily: "Inter",
          fontSize: 12,
          lineHeight: "20px",
          letterSpacing: 0,
          "-webkit-font-smoothing": "antialiased",
          textTransform: "uppercase",
        },
      });
    }),
  ],
};
